@import url(https://fonts.googleapis.com/css2?family=Bayon&display=swap);
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.rc-time-picker {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}
.rc-time-picker * {
  box-sizing: border-box;
}
.rc-time-picker-clear {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 3px;
  margin: 0;
}
.rc-time-picker-clear-icon:after {
  content: 'x';
  font-size: 12px;
  font-style: normal;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  height: 20px;
  width: 20px;
  transition: color 0.3s ease;
}
.rc-time-picker-clear-icon:hover:after {
  color: #666;
}
.rc-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 28px;
  padding: 1rem 1rem;
  color: #666;
  font-size: 12px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: text;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rc-time-picker-input[disabled] {
  color: #ccc;
  background: #f7f7f7;
  cursor: not-allowed;
}

.rc-time-picker-input[disabled]::-webkit-input-placeholder {
  color: black;
}

.rc-time-picker-input[disabled]:-ms-input-placeholder {
  color: black;
}

.rc-time-picker-input[disabled]::placeholder {
  color: black;
}

.rc-time-picker-panel {
  position: absolute;
  z-index: 1070;
  box-sizing: border-box;
  width: 170px;
}
.rc-time-picker-panel * {
  box-sizing: border-box;
}
.rc-time-picker-panel-inner {
  position: relative;
  display: inline-block;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 1px 5px #ccc;
}
.rc-time-picker-panel-narrow {
  max-width: 113px;
}
.rc-time-picker-panel-input {
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  border: 1px solid transparent;
  outline: 0;
  cursor: auto;
}
.rc-time-picker-panel-input-wrap {
  position: relative;
  box-sizing: border-box;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}
.rc-time-picker-panel-input-invalid {
  border-color: red;
}
.rc-time-picker-panel-select {
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  margin-left: -1px;
  overflow-y: auto;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
}
.rc-time-picker-panel-select-active {
  overflow-y: auto;
}
.rc-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.rc-time-picker-panel-select:last-child {
  border-right: 0;
}
.rc-time-picker-panel-select ul {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.rc-time-picker-panel-select li {
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0 0 0 16px;
  line-height: 24px;
  text-align: left;
  list-style: none;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rc-time-picker-panel-select li:hover {
  background: #edfaff;
}
li.rc-time-picker-panel-select-option-selected {
  font-weight: bold;
  background: #f7f7f7;
}
li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

